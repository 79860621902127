var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "Modal",
        {
          attrs: { title: "Rapport de pénalités" },
          on: { close: _vm.onPenaltyReportModalClose },
          model: {
            value: _vm.showPenaltiesReportModal,
            callback: function($$v) {
              _vm.showPenaltiesReportModal = $$v
            },
            expression: "showPenaltiesReportModal"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table mb-0" }, [
                  _c("thead", { staticClass: "thead-light" }, [
                    _c("tr", [
                      _c("th", [_vm._v("ID")]),
                      _c("th", [_vm._v("N° Facture")]),
                      _c("th", [_vm._v("Date Facture")]),
                      _c("th", [_vm._v("Montant HT")]),
                      _c("th", [_vm._v("Montant de Pénalité")]),
                      _c("th", [_vm._v("Montant Aprés pénalité")])
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.penaltiesReport, function(penalty, index) {
                      return _c("tr", { key: index }, [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(index + 1))
                        ]),
                        _c("td", [_vm._v(_vm._s(penalty.invNo))]),
                        _c("td", [_vm._v(_vm._s(penalty.invoiceDate))]),
                        _c("td", [_vm._v(_vm._s(penalty.untaxedAmnt))]),
                        _c("td", [_vm._v(_vm._s(penalty.penaltyAmnt))]),
                        _c("td", [_vm._v(_vm._s(penalty.finalAmnt))])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { title: "Changer le contrat" },
          model: {
            value: _vm.showChangeContractModal,
            callback: function($$v) {
              _vm.showChangeContractModal = $$v
            },
            expression: "showChangeContractModal"
          }
        },
        [
          _c("form", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("label", { attrs: { for: "formrow-inputCity" } }, [
                    _vm._v(" Contrat * ")
                  ]),
                  _c("multiselect", {
                    attrs: {
                      searchable: true,
                      "track-by": "id",
                      "open-direction": "bottom",
                      label: "ref",
                      "select-label": "",
                      options: _vm.contractsList,
                      placeholder: "",
                      "allow-empty": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "singleContract",
                        fn: function(ref) {
                          var contract = ref.contract
                          return [_vm._v(_vm._s(contract.ref))]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.contractIdChange,
                      callback: function($$v) {
                        _vm.contractIdChange = $$v
                      },
                      expression: "contractIdChange"
                    }
                  })
                ],
                1
              )
            ]),
            _c("br"),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.changeContractHandle($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-check" }),
                  _vm._v(" Appliquer ")
                ]
              )
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "contractDashboard" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-4" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "mr-4" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.getContractDashboard($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "mdi mdi-cloud-refresh text-primary h1"
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _c(
                            "h5",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _vm._v(
                                " Contrat N°: " +
                                  _vm._s(_vm.contractData.ref) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "float-right",
                              staticStyle: { display: "inline-block" },
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.changeContractClick($event)
                                }
                              }
                            },
                            [_vm._v("Changer le contrat")]
                          ),
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(" Objet: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.contractData.object) + ".")
                            ])
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(" Type: "),
                            _c("span", { staticClass: "badge badge-primary" }, [
                              _vm._v(_vm._s(_vm.contractData.type))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "card-body border-top" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("div", [
                          _c("p", { staticClass: "text-muted mb-2" }, [
                            _vm._v("Prestataire")
                          ]),
                          _c("span", { staticClass: "badge badge-info" }, [
                            _vm._v(_vm._s(_vm.contractData.supplier))
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("div", [
                          _c("p", { staticClass: "text-muted mb-2" }, [
                            _vm._v("Date début")
                          ]),
                          _c("h5", [
                            _c("span", { staticClass: "badge badge-success" }, [
                              _vm._v(_vm._s(_vm.contractData.startDate))
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("div", [
                          _c("p", { staticClass: "text-muted mb-2" }, [
                            _vm._v("Date fin")
                          ]),
                          _c("h5", [
                            _c("span", { staticClass: "badge badge-warning" }, [
                              _vm._v(_vm._s(_vm.contractData.endDate))
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c("div", [
                          _c("p", { staticClass: "text-muted mb-2" }, [
                            _vm._v("Status")
                          ]),
                          _c("h5", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.contractData.status)
                              }
                            })
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-xl-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body pb-0" }, [
                    _c("div", { staticClass: "clearfix" }, [
                      _c("h4", { staticClass: "card-title mb-4" }, [
                        _vm._v("Informations Financières")
                      ])
                    ]),
                    _c("div", { staticClass: "text-muted text-center" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(" Montant disponible "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover",
                              value:
                                "Le montant exploitable du contrat N°: " +
                                _vm.contractData.ref +
                                " aprés le paiement des factures du prestataire: " +
                                _vm.contractData.supplier +
                                ".",
                              expression:
                                "\n                        `Le montant exploitable du contrat N°: ${contractData.ref} aprés le paiement des factures du prestataire: ${contractData.supplier}.`\n                      ",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fas fa-question-circle mr-2 helpIcon"
                        })
                      ]),
                      _c("h4", [
                        _vm._v(" " + _vm._s(_vm.financeData.leftAmnt) + " ")
                      ])
                    ]),
                    _c("div", { staticClass: "table-responsive mt-4 mb-0" }, [
                      _c("table", { staticClass: "table table-centered" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("h5", { staticClass: "font-size-14 mb-1" }, [
                                _vm._v(" Montant contractuel "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover",
                                      value:
                                        "Le montant du contrat N°: " +
                                        _vm.contractData.ref,
                                      expression:
                                        "\n                                `Le montant du contrat N°: ${contractData.ref}`\n                              ",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass:
                                    "fas fa-question-circle mr-2 helpIcon"
                                })
                              ]),
                              _c("p", { staticClass: "text-muted mb-0" })
                            ]),
                            _c("td"),
                            _c("td", [
                              _c("p", { staticClass: "text-muted mb-1" }),
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.financeData.planned_amount) +
                                    " "
                                ),
                                _c("span", {
                                  staticClass:
                                    "badge badge-primary ml-1 align-bottom"
                                })
                              ])
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("h5", { staticClass: "font-size-14 mb-1" }, [
                                _vm._v(" Consommations (ODS) "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover",
                                      value:
                                        "La consommation des différents Ordres de services relatives a cet contrat.",
                                      expression:
                                        "\n                                `La consommation des différents Ordres de services relatives a cet contrat.`\n                              ",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass:
                                    "fas fa-question-circle mr-2 helpIcon"
                                })
                              ])
                            ]),
                            _c("td"),
                            _c("td", [
                              _c("p", { staticClass: "text-muted mb-1" }),
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.financeData.amountOds) + " "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-primary ml-1 align-bottom"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.financeData.amountPercentage) +
                                        " %"
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("h5", { staticClass: "font-size-14 mb-1" }, [
                                _vm._v(" Factures a payer "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover",
                                      value:
                                        "Le montant total des factures en attente de paiement du prestataire: " +
                                        _vm.contractData.supplier +
                                        " pour le contrat N°: " +
                                        _vm.contractData.ref +
                                        ".",
                                      expression:
                                        "\n                                `Le montant total des factures en attente de paiement du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`\n                              ",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass:
                                    "fas fa-question-circle mr-2 helpIcon"
                                })
                              ])
                            ]),
                            _c("td"),
                            _c("td", [
                              _c("p", { staticClass: "text-muted mb-1" }),
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.financeData.invoicedAmnt) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-info ml-1 align-bottom"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.financeData.invoicedPercentage
                                      ) + " %"
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("h5", { staticClass: "font-size-14 mb-1" }, [
                                _vm._v(" Factures Payé "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover",
                                      value:
                                        "Le montant total des factures payés du prestataire: " +
                                        _vm.contractData.supplier +
                                        " pour le contrat N°: " +
                                        _vm.contractData.ref +
                                        ".",
                                      expression:
                                        "\n                                `Le montant total des factures payés du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`\n                              ",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass:
                                    "fas fa-question-circle mr-2 helpIcon"
                                })
                              ]),
                              _c("p", { staticClass: "text-muted mb-0" })
                            ]),
                            _c("td"),
                            _c("td", [
                              _c("p", { staticClass: "text-muted mb-1" }),
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.financeData.paidAmnt) + " "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-danger ml-1 align-bottom"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.financeData.paidPercentage) +
                                        " %"
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm.penaltiesData.hasPenalties
                ? _c("div", { staticClass: "col-xl-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body pb-0" }, [
                        _c("div", { staticClass: "clearfix" }, [
                          _c("h4", { staticClass: "card-title mb-4" }, [
                            _vm._v("Pénalités")
                          ])
                        ]),
                        _c("div", { staticClass: "text-muted text-center" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(" Total pénalités "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover",
                                  value:
                                    "Le montant total des pénalités appliqués dans le contrat N°: " +
                                    _vm.contractData.ref +
                                    " pour le prestataire: " +
                                    _vm.contractData.supplier +
                                    ".",
                                  expression:
                                    "\n                        `Le montant total des pénalités appliqués dans le contrat N°: ${contractData.ref} pour le prestataire: ${contractData.supplier}.`\n                      ",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass:
                                "fas fa-question-circle mr-2 helpIcon"
                            })
                          ]),
                          _c("h4", [
                            _vm._v(
                              " " + _vm._s(_vm.penaltiesData.totalAmnt) + " "
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "table-responsive mt-4 mb-0" },
                          [
                            _c(
                              "table",
                              { staticClass: "table table-centered" },
                              [
                                _c(
                                  "tbody",
                                  _vm._l(_vm.penaltiesData.penalties, function(
                                    penalty,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c(
                                          "h5",
                                          { staticClass: "font-size-14 mb-1" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "javascript:void(0)"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.onPenaltyNameLnkClick(
                                                      penalty
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(index))]
                                            ),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "b-popover",
                                                  rawName: "v-b-popover.hover",
                                                  value:
                                                    "Le montant de la pénalité: " +
                                                    index,
                                                  expression:
                                                    "\n                                `Le montant de la pénalité: ${index}`\n                              ",
                                                  modifiers: { hover: true }
                                                }
                                              ],
                                              staticClass:
                                                "fas fa-question-circle mr-2 helpIcon"
                                            })
                                          ]
                                        ),
                                        _c("p", {
                                          staticClass: "text-muted mb-0"
                                        })
                                      ]),
                                      _c("td"),
                                      _c("td", [
                                        _c("p", {
                                          staticClass: "text-muted mb-1"
                                        }),
                                        _c("h5", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(penalty.withCurrency) +
                                              " "
                                          ),
                                          _c("span", {
                                            staticClass:
                                              "badge badge-primary ml-1 align-bottom"
                                          })
                                        ])
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.warrantiesData.hasWarranties
                ? _c("div", { staticClass: "col-xl-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body pb-0" }, [
                        _c("div", { staticClass: "clearfix" }, [
                          _c("h4", { staticClass: "card-title mb-4" }, [
                            _vm._v("Garanties")
                          ])
                        ]),
                        _c("div", { staticClass: "text-muted text-center" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(" Total garanties "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover",
                                  value:
                                    "Le montant total des garanties appliqués dans le contrat N°: " +
                                    _vm.contractData.ref +
                                    " pour le prestataire: " +
                                    _vm.contractData.supplier +
                                    ".",
                                  expression:
                                    "\n                        `Le montant total des garanties appliqués dans le contrat N°: ${contractData.ref} pour le prestataire: ${contractData.supplier}.`\n                      ",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass:
                                "fas fa-question-circle mr-2 helpIcon"
                            })
                          ]),
                          _c("h4", [
                            _vm._v(
                              " " + _vm._s(_vm.warrantiesData.totalAmnt) + " "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "col-xl-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    { staticClass: "card-body pb-0" },
                    [
                      _c("div", { staticClass: "clearfix" }, [
                        _c("h4", { staticClass: "card-title mb-4" }, [
                          _vm._v(" Prestations "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value:
                                  "Une synthèse sur les différents prestations du contrat N°: " +
                                  _vm.contractData.ref +
                                  ".",
                                expression:
                                  "\n                        `Une synthèse sur les différents prestations du contrat N°: ${contractData.ref}.`\n                      ",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fas fa-question-circle mr-2 helpIcon"
                          })
                        ])
                      ]),
                      _vm._l(_vm.charts.servicesAnalytics, function(service) {
                        return _c(
                          "div",
                          {
                            key: service.name,
                            staticClass: "row mb-2 border-bottom"
                          },
                          [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("h5", { staticClass: "font-size-14 mb-1" }, [
                                _c(
                                  "span",
                                  { staticClass: "badge badge-primary" },
                                  [_vm._v(_vm._s(service.name))]
                                )
                              ]),
                              _c("p", { staticClass: "text-muted mb-0" }, [
                                _vm._v(" Montant Contractuel: "),
                                _c("br"),
                                _c("b", [
                                  _vm._v(_vm._s(service.contractualAmnt))
                                ])
                              ]),
                              _c("p", { staticClass: "text-muted mb-0" }, [
                                _vm._v(" Montant Réalisé:"),
                                _c("br"),
                                _c("b", [_vm._v(_vm._s(service.realizedAmnt))])
                              ]),
                              _c("p", { staticClass: "text-muted mb-0" }, [
                                _vm._v(" Montant Disponible:"),
                                _c("br"),
                                _c("b", [_vm._v(_vm._s(service.leftAmnt))])
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("RadialBar", {
                                  attrs: {
                                    seriesvalue: service.realizationPercentage,
                                    Chartcolor: service.realizationColor
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm.hseData.show
                ? _c("div", { staticClass: "col-xl-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body pb-0" }, [
                        _c("div", { staticClass: "clearfix" }, [
                          _c("h4", { staticClass: "card-title mb-4" }, [
                            _vm._v("HSE")
                          ])
                        ]),
                        _c("div", { staticClass: "text-muted text-center" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v("Note Globale:")
                          ]),
                          _c("h4", [
                            _vm._v(" " + _vm._s(_vm.hseData.globalAvg) + " ")
                          ]),
                          _c("img", {
                            staticClass: "rounded-circle avatar-sm",
                            attrs: {
                              alt: _vm.hseData.globalAvg,
                              title: _vm.hseData.globalAvg,
                              src: require("@/assets/images/base/controls/" +
                                _vm.hseData.emoji +
                                ".png"),
                              "data-holder-rendered": "true"
                            }
                          })
                        ]),
                        _c(
                          "div",
                          { staticClass: "table-responsive mt-4 mb-0" },
                          [
                            _c(
                              "table",
                              { staticClass: "table table-centered" },
                              [
                                _c(
                                  "tbody",
                                  _vm._l(_vm.hseData.controls, function(
                                    elem,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c(
                                          "h5",
                                          { staticClass: "font-size-14 mb-1" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(elem.type) + " "
                                            )
                                          ]
                                        ),
                                        _c("p", {
                                          staticClass: "text-muted mb-0"
                                        })
                                      ]),
                                      _c("td"),
                                      _c("td", [
                                        _c("p", {
                                          staticClass: "text-muted mb-1"
                                        }),
                                        _c("h5", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(elem.avg_formated) +
                                              " "
                                          ),
                                          _c("span", {
                                            staticClass:
                                              "badge badge-primary ml-1 align-bottom"
                                          })
                                        ])
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "col-xl-8" }, [
            _c("div", { staticClass: "col-xl-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h6", { staticClass: "text-muted mb-4" }, [
                        _c("i", {
                          staticClass:
                            "fas fa-file-invoice-dollar h2 text-primary align-middle mb-0 mr-3"
                        }),
                        _vm._v(" Factures Prestataire ")
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5 text-center" }, [
                          _vm._v(" Nbr de factures "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value:
                                  "Le nombre total des factures en attente de paiement du prestataire: " +
                                  _vm.contractData.supplier +
                                  " pour le contrat N°: " +
                                  _vm.contractData.ref +
                                  ".",
                                expression:
                                  "\n                          `Le nombre total des factures en attente de paiement du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`\n                        ",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fas fa-question-circle mr-2 helpIcon"
                          }),
                          _c("h2", [
                            _vm._v(
                              _vm._s(_vm.financeData.countSuppliersInvoices)
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "col-md-7 text-center" }, [
                          _vm._v(" Total Factures Impayés "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value:
                                  "Le montant total des factures en attente de paiement du prestataire: " +
                                  _vm.contractData.supplier +
                                  " pour le contrat N°: " +
                                  _vm.contractData.ref +
                                  ".",
                                expression:
                                  "\n                          `Le montant total des factures en attente de paiement du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`\n                        ",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fas fa-question-circle mr-2 helpIcon"
                          }),
                          _c("h2", [
                            _vm._v(_vm._s(_vm.financeData.invoicedAmnt))
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h6", { staticClass: "text-muted mb-4" }, [
                        _c("i", {
                          staticClass:
                            "fas fa-money-bill h2 text-warning align-middle mb-0 mr-3"
                        }),
                        _vm._v(" Paiements ")
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 text-center" }, [
                          _vm._v(" Total paiements "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value:
                                  "Le montant total des factures payés du prestataire: " +
                                  _vm.contractData.supplier +
                                  " pour le contrat N°: " +
                                  _vm.contractData.ref +
                                  ".",
                                expression:
                                  "\n                          `Le montant total des factures payés du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`\n                        ",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fas fa-question-circle mr-2 helpIcon"
                          }),
                          _c("h2", [_vm._v(_vm._s(_vm.financeData.paidAmnt))])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-xl-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h4", { staticClass: "card-title mb-3" }, [
                    _vm._v(" Aperçu "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover",
                          value:
                            "Comparaison de consommation des différentes prestations du contrat N°: " +
                            _vm.contractData.ref +
                            " durant une année.",
                          expression:
                            "\n                    `Comparaison de consommation des différentes prestations du contrat N°: ${contractData.ref} durant une année.`\n                  ",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "fas fa-question-circle mr-2 helpIcon"
                    })
                  ]),
                  _c(
                    "div",
                    [
                      _c("div", {
                        staticClass: "apex-charts",
                        attrs: { id: "overview-chart", dir: "ltr" }
                      }),
                      _c("apexchart", {
                        staticClass: "apex-charts",
                        attrs: {
                          height: "240",
                          type: "line",
                          dir: "ltr",
                          series: _vm.charts.contractMonthlyOverview.series,
                          options:
                            _vm.charts.contractMonthlyOverview.chartOptions
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "col-xl-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "clearfix" }, [
                    _c("h4", { staticClass: "card-title mb-4" }, [
                      _vm._v("Consommation Mensuelle")
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c("div", { staticClass: "text-muted" }, [
                        _c("div", { staticClass: "mb-4" }, [
                          _c("p", [_vm._v("Ce mois")]),
                          _c("h4", [
                            _vm._v(_vm._s(_vm.financeData.thisMonthConsumption))
                          ]),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill font-size-11 mr-1",
                                class:
                                  _vm.financeData.differenceType == "drop"
                                    ? "badge-soft-success"
                                    : "badge-soft-danger"
                              },
                              [
                                _c("i", {
                                  staticClass: "fas",
                                  class:
                                    _vm.financeData.differenceType == "drop"
                                      ? "fa-angle-down"
                                      : "fa-angle-up"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.financeData.differencePercente) +
                                    "% "
                                )
                              ]
                            ),
                            _vm._v(" Du mois précédent ")
                          ])
                        ]),
                        _c("div", { staticClass: "mt-4" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v("Dernier mois")
                          ]),
                          _c("h5", [
                            _vm._v(_vm._s(_vm.financeData.lastMonthConsumption))
                          ])
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-lg-9" },
                      [
                        _c("apexchart", {
                          staticClass: "apex-charts",
                          attrs: {
                            dir: "ltr",
                            height: "320",
                            series:
                              _vm.charts.contractMonthlyConsumption.series,
                            options:
                              _vm.charts.contractMonthlyConsumption.chartOptions
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-xl-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h4", { staticClass: "card-title mb-4" }, [
                        _vm._v(" Répartition ODS / Prestation ")
                      ]),
                      _c("div", { staticClass: "table-responsive mt-4" }, [
                        _c("table", { staticClass: "table table-centered" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th"),
                              _c("th", [_vm._v("N° ODS")]),
                              _c("th", [_vm._v("Montant")])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.odsByService, function(elem, index) {
                              return _c("tr", { key: index }, [
                                _c("td", { staticStyle: { width: "140px" } }, [
                                  _c("h6", { staticClass: "mb-0" }, [
                                    _vm._v(_vm._s(elem.name))
                                  ])
                                ]),
                                _c("td", { staticStyle: { width: "120px" } }, [
                                  _c("h5", { staticClass: "mb-0" }, [
                                    _vm._v(_vm._s(elem.countOds.formated))
                                  ])
                                ]),
                                _c("td", [
                                  _c("h5", [
                                    _vm._v(_vm._s(elem.amntOds.withCurrency))
                                  ])
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h4", { staticClass: "card-title mb-4" }, [
                        _vm._v(" Réalisation Prestation / Base de vie ")
                      ]),
                      _c("div", { staticClass: "table-responsive mt-4" }, [
                        _c("table", { staticClass: "table table-centered" }, [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("th"),
                                _vm._l(_vm.odsByServiceByCamp.camps, function(
                                  camp,
                                  index
                                ) {
                                  return _c("th", { key: index }, [
                                    _vm._v(" " + _vm._s(camp) + " ")
                                  ])
                                })
                              ],
                              2
                            )
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.odsByServiceByCamp.services, function(
                              service,
                              index
                            ) {
                              return _c(
                                "tr",
                                { key: index },
                                [
                                  _c(
                                    "td",
                                    { staticStyle: { width: "140px" } },
                                    [
                                      _c("h6", { staticClass: "mb-0" }, [
                                        _vm._v(_vm._s(service))
                                      ])
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.odsByServiceByCamp
                                      .campHasDataForService,
                                    function(servicesAmnt, index) {
                                      return _c("td", { key: index }, [
                                        _c("h5", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                servicesAmnt[service]
                                                  ? servicesAmnt[service]
                                                      .amntOds.withCurrency
                                                  : "0.00 DA"
                                              ) +
                                              " "
                                          )
                                        ])
                                      ])
                                    }
                                  )
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-xl-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "clearfix" }, [
                    _c("h4", { staticClass: "card-title mb-4" }, [
                      _vm._v(" Contrôles HSE "),
                      _c("i", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover",
                            value:
                              "Une synthèse sur les différents contrôles HSE du prestataire : " +
                              _vm.contractData.supplier +
                              " pour le contrat N°: " +
                              _vm.contractData.ref +
                              ".",
                            expression:
                              "\n                      `Une synthèse sur les différents contrôles HSE du prestataire : ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`\n                    ",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "fas fa-question-circle mr-2 helpIcon"
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "div",
                        { staticClass: "text-muted text-center mt-5" },
                        [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v("Note Globale:")
                          ]),
                          _c("h4", [
                            _vm._v(" " + _vm._s(_vm.hseData.globalAvg) + " ")
                          ]),
                          _c("img", {
                            staticClass: "rounded-circle avatar-sm",
                            attrs: {
                              alt: _vm.hseData.globalAvg,
                              title: _vm.hseData.globalAvg,
                              src: require("@/assets/images/base/controls/" +
                                _vm.hseData.emoji +
                                ".png"),
                              "data-holder-rendered": "true"
                            }
                          })
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-lg-9" },
                      [
                        _c("apexchart", {
                          staticClass: "apex-charts",
                          attrs: {
                            dir: "ltr",
                            height: "320",
                            series: _vm.charts.hseChart.series,
                            options: _vm.charts.hseChart.chartOptions
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }