<script>
export default {
  props: {
    Chartcolor: {
      type: String,
      default: "",
    },
    seriesvalue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      chartData: {
          series: [this.seriesvalue],
          chartOptions: {
              colors: [this.Chartcolor],
            chart: {
              height: 350,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: '70%',
                }
              },
            },
            labels: ['Réalisé'],
          },
      },
    };
  },
};
</script>

<template>
  <apexchart
    type="radialBar"
    height="200"
    :options="chartData.chartOptions"
    :series="chartData.series"
  ></apexchart>
</template>
